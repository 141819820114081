.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  grid-area: left / bottom;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 0;
}

video {
  height: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.center {
    grid-area: center;
    min-height: 0;
    min-width: 0;
  }

.grid {
    width: 100%;
    height: 100%;
    
    display: grid;
    grid-gap: 20px;
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
}



