.videobarContainer {
  min-height: 200px;
  gap: 24px;
  padding: 0 24px;
  align-items: center;
  overflow-x: auto;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  
}

.videoBox {
  position: relative;
  padding-top: 50%;

}

.videoPlaceholder {
  width: 100%;
  height: 100%;
  position: relative;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.videoContainer video {
  width: 100%;
  height: 100%;
}


.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 12px 0 12px;
}

.left,
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 26px;
  border-radius: 18px;
  background-color: #1f2328b2;
}

.right span {
  display: inline-flex;
  align-items: center;
}

.right span::before {
  content: "";
  width: 10px;
  height: 10px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #e72d2d;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 100%;
  background-color: #1f2328b2;
  text-transform: uppercase;
  text-align: center;
}

.bottom span {
  font-size: 11px;
}

.active {
  box-shadow: 0 0 3px 5px #0078d4;
}

.realtime {
  /* height: 170px;
  width: 240px; */
}
