.modal {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 225px;
  padding: 64px;
  padding-top: 110px;
  gap: 16px;
  /* As 3d scene controls have 999 */
  z-index: 1000;
  background-color: rgb(11, 3, 24);

  &.isActive {
    display: flex;
  }
}

.back {
  align-self: flex-start;
  align-items: center;
  background-color: #2F74F6;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 12px;
}

.title {
  font-size: 28px;
  color: #fff;
  margin: 0;
}