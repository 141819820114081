.sidebarContainer {
  grid-area: right;
  display: flex;
  flex-direction: column;
  justify-self: end;
  height: 100vh;
  width: 360px;
  padding: 0 15px;
  overflow: hidden;
  gap: 10px;
  background: linear-gradient(#000000, #1c2555);
}
