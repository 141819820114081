.center {
  grid-area: center;
  min-height: 0;
  min-width: 0;
}

.left {
  grid-area: left;
  display: flex;
  justify-self: start;
  height: 100vh;
  width: 225px;
  flex-direction: column;
}

.top {
  grid-area: top;
  display: flex;
  justify-content: center;
}

.bottom {
  grid-area: bottom;
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 30px;
  margin-bottom: 20px;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 21px;
  margin-left: 15px;
  color: #fff;
  font-size: 12px;
}

.delimiter {
  width: 100%;
  height: 0;
  border: 1px solid #7588bd;
  margin-top: 21px;
}

.tools::-webkit-scrollbar,
.tools {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 14px;
  margin-left: 15px;
  margin-right: 15px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tools::-webkit-scrollbar {
  display: none;
}

.project {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-family: "Segoe UI Semibold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #e3e5ee;
  margin-top: 8px;
}

.facilityLevels {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
}

.navigation {
  display: flex;
  justify-self: flex-end;
  margin: 24px 15px;
}

.info {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.jarvis {
  display: flex;
  flex: 0;
  flex-direction: column;
  margin-top: 38px;
}

.hardware {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow: hidden;
  /* padding-bottom: 10px; */
  height: 50vh;
  margin-bottom: 20px;
}

.stats {
  flex: 1;
}

.timeline {
  margin-top: 11px;
}