.select {
  background: transparent;
  border: 1px #7588bd solid;
  border-radius: 4px;

  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7588bd60;

  padding: 10px 0 7px 10px;
}

.select::placeholder {
  color: #7588bd;
}

select:focus {
  outline: none;
}
