.root {
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  gap: 8px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  color: #7588BD;
  text-transform: uppercase;
}

.tab.active {
  color: #fff;
}

.tableContainer {
  color: #fff;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.tableContainer table th, .tableContainer table td{
    padding-inline: 10px;
}

table {
  width: 100%;
}

thead {
  position: sticky;
  top: 0;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  background-color: rgb(16, 20, 47);
}

tbody {
  overflow-y: auto;
  font-size: 12px;
  height: 100%;
}

tr>th:first-of-type,
tr>td:first-of-type {
  width: 60px;
  padding: 8px;
}

tr>th:nth-of-type(2),
tr>td:nth-of-type(2) {
  width: 200px;
}

tr:nth-child(even) {
  background-color: rgb(16, 20, 47);
}

.capitalized {
  text-transform: capitalize;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid #2b9f83;
}

.icon path,
.detailedIcon path {
  fill: #fff;
}

.identifier {
  background-color: rgb(7, 0, 20);
  border-radius: 4px;
  padding: 4px;
}

.border-active {
  border-color: var(--device-status-active);
}

.border-inactive {
  border-color: var(--device-status-inactive);
}

.border-outage {
  border-color: var(--device-status-outage);
}

.text-active {
  color: var(--device-status-active);
}

.text-inactive {
  color: var(--device-status-inactive);
}

.text-outage {
  color: var(--device-status-outage);
}

/* Add more styles as per your design */
