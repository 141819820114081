.wrapper {
  background-color: #00000075;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
}

.wrapper video {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 0;
}

.active {
  z-index: 1!important;
}

.inactive {
  z-index: 0!important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e72d2e;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #55ccb1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #479ef5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.labelWrapper {
  color: #fff;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorText {
  color: #e72d2e;
}
