.container::-webkit-scrollbar,
.container {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  gap: 14px;
  margin-top: 15px;
  color: #d3ffff;
  text-shadow: #d3ffff 0 0 3px;
  background-image: url("../../resources/images/scenarios.gif");
  background-size: 70%;
  background-position: center center;
  background-repeat: no-repeat;
}

.container > p {
  text-align: center;
}
