.notificationWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.notificationWrapper::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: loading 3s 2s;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.notificationsWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* height: 100%; */
  height: 50vh;
  flex: 4;
  overflow: hidden;
  margin-top: 23px;
  font-family: "Segoe UI", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  padding: 8px 15px;
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  overflow-y: auto;
  padding-top: 10px;
  overflow-x: hidden;
}

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 315px;
  height: 48px;
  border-radius: 5px;
  padding: 10px;
  gap: 3px;
  background: #070D26;
  overflow: hidden;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  overflow: hidden;
  position: relative;
  flex: none;
}

.iconContainer > svg {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 29px;
  height: 29px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: 5px;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  position: relative;
}
.top:before {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #fff;
  display: block;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.primary {
  background-color: #007bff;
}

.secondary {
  background-color: #020324;
}

.emergency {
  background-color: #ed6e69;
}

.top span {
  display: inline-block;
  white-space: nowrap;
  padding-left: 10px;
}

/* .top::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to right, transparent 0%, #020324 100%);
} */

.primary .top::after {
  background: linear-gradient(to right, transparent 0%, #007bff 100%);
}

.emergency .top::after {
  background: linear-gradient(to right, transparent 0%, #ed6e69 100%);
}

.information:hover .top span {
  /* animation: scrollOnHover 3s linear infinite; */
}

.primary .top span,
.emergency .top span {
  font-size: 16px;
}

.fixedOnTop {
  position: fixed;
  top: 65px;
  z-index: 1;
}

.primary .iconContainer,
.emergency .iconContainer {
  position: relative;
  background-color: #262d35;
  border-radius: 50%;
  margin-right: 5px;
}

.primary .iconContainer::before,
.emergency .iconContainer::before {
  content: "";
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}

.progressBar {
  position: relative;
  flex-grow: 1;
  height: 5px;
}

.progressBar span {
  position: absolute;
  right: 0;
  top: -24px;
  font-size: 14px;
}

.fill {
  background: linear-gradient(90deg, rgba(24, 144, 255, 0.2) 0%, #1890ff 100%);
  height: 100%;
  transition: width 1s ease;
}

@keyframes scrollText {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
@keyframes scrollOnHover {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
