.container {
  display: flex;
  gap: 22px;
  width: 100%;
  flex-direction: column;
}

.routeContainer {
  display: flex;
  gap: 9px;
  flex-direction: row;
  cursor: pointer;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-family: "Segoe UI Semibold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #d6d6d6;
}

.subtitle {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #d6d6d6;
}
