.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.actionText {
  font-family: "Segoe UI", sans-serif;
  font-style: underline;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  background: none;
  margin-right: 12px;
  cursor: pointer;
}
