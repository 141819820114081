.pageContainer {
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  grid-template-columns: min-content 4fr 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    "left top right"
    "left center right"
    "left bottom right";
  background: radial-gradient(
    circle farthest-corner at center center,
    #212b6e 0%,
    #000 100%
  );
}
