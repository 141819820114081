.hardware::-webkit-scrollbar,
.hardware {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;
  /* padding-bottom: 24px; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  font-family: "Segoe UI", sans-serif;
  font-style: normal;
  height: 100%;
  overflow: hidden;
}

.device {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background: #070d26;
  border-radius: 4px;
}

.delimiter {
  height: 80%;
  margin: 0 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid var(--device-status-active);
}

.border-active {
  border-color: var(--device-status-active);
}

.border-inactive {
  border-color: var(--device-status-inactive);
}

.border-outage {
  border-color: var(--device-status-outage);
}

.text-active {
  color: var(--device-status-active);
}

.text-inactive {
  color: var(--device-status-inactive);
}

.text-outage {
  color: var(--device-status-outage);
}

.icon path,
.detailedIcon path {
  fill: #fff;
}

.divider {
  margin: 0 12px 0 4px;
}

.text {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}

.status {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 6px 3px;
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-gap: 10px;
}

.status span {
  text-overflow: fade;
  overflow: hidden;
  text-transform: capitalize;
}

.priority {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 4px 6px 3px;
  background: #2f74f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}

.filters input {
  flex: 2;
}

.filters select {
  flex: 1;
}

.name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.nameContainer {

}

.statusHardware {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.1;
    color: #CFCFCF;
}

.green {
    color: #2B9F83 !important;
}
.yellow {
    color: #EBB24C !important;
}

.devLocation {
    font-size: 12px;
    color: #ffffff;
    line-height: 1.1;
    font-weight: 300;
}
