.tree {
  list-style-type: none;
  padding: 0;
  /* margin-top: 10px; */
}

.node {
  position: relative;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.label {
  font-family: "Segoe UI Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
}

.disabled {
  cursor: default;
  color: #ffffff60;
}

.labelOpen {
  color: #479ef5;
}

.branch {
  margin-left: 20px;
}

.cursorPointer {
  cursor: pointer;
}
