.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    grid-area: left / bottom;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    overflow: hidden;
  }
  
  .sidebarContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 15px 0;
  }
  
  video {
    height: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    object-fit: cover;
  }

  .notificationsWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    mask-image: linear-gradient(
      to bottom,
      black 0%,
      black calc(100% - 60px),
      transparent 100%
    );
    width: 100%;
    height: 100%;
    flex: 4;
    overflow: hidden;
    margin-top: 23px;
    font-family: "Segoe UI", sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
  }
  
  .notificationsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    overflow-y: auto;
    padding-top: 70px;
  }
  
  .notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 315px;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    gap: 15px;
  }
  
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
  }
  
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width: 100%;
    gap: 5px;
  }
  
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin-right: 80px;
  }
  
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .primary {
    background-color: #007bff;
  }
  
  .secondary {
    background-color: #020324;
  }
  
  .emergency {
    background-color: #ed6e69;
  }
  
  .top span {
    display: inline-block;
    padding-left: 18%;
    white-space: nowrap;
    overflow-x: scroll; 
    animation: scrollText 4s linear;
    font-size: 15px;
  }
  
  .top::after {
    content: "";
    position: absolute;
    top: 0;
    right: -2%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to right, transparent 90%, #020324 100%);
  }
  
  .primary .top::after {
    background: linear-gradient(to right, transparent 90%, #007bff 100%);
  }
  
  .emergency .top::after {
    background: linear-gradient(to right, transparent 90%, #ed6e69 100%);
  }
  
  /* .information:hover .top span {
    animation: scrollOnHover 3s linear infinite;
  } */
  
  .primary .top span,
  .emergency .top span {
    font-size: 16px;
  }
  
  .fixedOnTop {
    position: fixed;
    top: 65px;
    z-index: 1;
  }
  
  .primary .iconContainer,
  .emergency .iconContainer {
    position: relative;
    background-color: #262d35;
    border-radius: 50%;
    /* margin-right: 5px; */
  }
  
  .primary .iconContainer::before,
  .emergency .iconContainer::before {
    content: "";
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
  
  .progressBar {
    position: relative;
    flex-grow: 1;
    height: 5px;
  }
  
  .progressBar span {
    position: absolute;
    right: 0;
    top: -24px;
    font-size: 14px;
  }
  
  .fill {
    background: linear-gradient(90deg, rgba(24, 144, 255, 0.2) 0%, #1890ff 100%);
    height: 100%;
    transition: width 1s ease;
  }
  
  @keyframes scrollText {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* @keyframes scrollOnHover {
    0% {
      transform: translateX(100%);
    }
  
    100% {
      transform: translateX(-100%);
    }
  }
   */