.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
  color: #7588BD;
}

.title::before {
  content: "";
  display: inline-block;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #7588BD;
  border-right: 1px solid #7588BD;
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.expanded::before {
  margin-bottom: 4px;

  transform: rotate(135deg);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  overflow-y: scroll;
  transition: all 0.3s ease;
  padding-right: 1rem;
  /*  only Firefox - scrollbar style */
  scrollbar-color: #7588bd #00800000;
  scrollbar-width: thin;
}

.collapsed {
  max-height: 0;
}
