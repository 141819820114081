/* Provide sufficient contrast against white background */
:root {
  --device-status-active: #2b9f83;
  --device-status-inactive: #CFCFCF;
  --device-status-outage: #ED6E69;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
    background-color: transparent;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
    scrollbar-gutter: stable;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

:hover::-webkit-scrollbar-thumb {
  background-color: #7588bd
} 


@font-face {
  font-family: "segoe-ui";
  src: local("Segoe UI"),
    url("./resources/fonts/segoe-ui/segoeui.ttf") format("truetype");
}

@font-face {
  font-family: "segoe-ui-bold";
  src: local("Segoe UI Bold"),
    url("./resources/fonts/segoe-ui/segoe-ui-bold.ttf") format("truetype");
}

@font-face {
  font-family: "segoe-ui-semibold";
  src: local("Segoe UI Semibold"),
    url("./resources/fonts/segoe-ui/segoe-ui-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "segoe-ui-light";
  src: local("Segoe UI Light"),
    url("./resources/fonts/segoe-ui/segoe-ui-light.ttf") format("truetype");
}

@font-face {
  font-family: "segoe-ui-semilight";
  src: local("Segoe UI"),
    url("./resources/fonts/segoe-ui/segoe-ui-semilight.ttf") format("truetype");
}

/* override pointer cursor on 3D iot component */
.root-242, .root-237{
  cursor: default !important;
}