.cb-adt-3d-viewer-layers-picker {
  visibility: hidden;
}

.cb-deeplink-flyout-root {
  visibility: hidden;
}

.cb-SceneRefreshButton-root {
  visibility: hidden;
}

.cb-ViewerElementsPanelRenderer-root {
  display: none;
}

.cb-camera-controls-root {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.cb-camera-controls-root>.ms-FocusZone {
  margin-left: 0 !important;
}

.cb-camera-controls-root .cb-HeaderControlGroup-root {
  background: none;
  border: none;
  box-shadow: 0 0 2px #3d487d;
}

.cb-camera-controls-root .cb-HeaderControlGroup-root button {
  background-color: #1a2350;
}

/* if buttons group has 2 buttons - this will remove inner border radius for the first button*/
.cb-camera-controls-root .cb-HeaderControlGroup-root button:first-child:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* if buttons group has 2 buttons - this will remove inner border radius for the last button*/
.cb-camera-controls-root .cb-HeaderControlGroup-root button:last-child:not(:only-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cb-scene-view-wrapper-root {
  background: transparent !important;
}

.cb-behaviors-modal-boundary-layer,
.cb-viewer-breadcrumb-container {
  display: none;
}

.ms-Stack:has(.cb-SceneRefreshButton-root) {
  display: none;
}

/* Icons overrides */
.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i {
  font-size: 0 !important;
  background: #070d26;
  width: 100%;
  height:100%;
  display: block;
  border-radius: 50%;
  background-size: 90%;
  background-position: center center;
  background-repeat: no-repeat;
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="RedEye"] {
  background-image: url("../../resources/images/tempreture.svg");
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="Drop"] {
  background-image: url("../../resources/images/humidity.svg");
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="Light"] {
  background-image: url("../../resources/images/lamp.svg");
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="Running"] {
  background-image: url("../../resources/images/motion.svg");
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="Frigid"] {
  background-image: url("../../resources/images/tempreture.svg");
}

.cb-visuals-modal-boundary-layer .cb-elements-panel-badge-circle > i[data-icon-name="Squalls"] {
  background-image: url("../../resources/images/fan.svg");
}

.cb-alert-badge-single-container .cb-alert-badge-count-badge {
  font-size: 0;
  background-color: #479ef5;
  background-image: url("../../resources/images/list.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cb-alert-badge-single-container {
  background: transparent !important;
}