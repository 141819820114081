.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  min-height: 110px;
}

.statsContainer::-webkit-scrollbar,
.statsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 8px;
}

.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 80px;
  padding: 10px;
  gap: 4px;
  font-family: "Segoe UI", sans-serif;
  color: #fff;
  background: #020324;
}

.title {
  font-size: 10px;
  line-height: 11px;
}

.value {
  color: #eefffe;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.description {
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
}

.fraction {
  color: #a4b8ff;
  font-weight: 300;
}

.temperatureRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.temperatureRow .title {
  flex: 2;
}

.temperatureRow .value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

}

.valueRow {
  display: flex;
  align-items: center;
  gap: 4px;
}
