.card {
  position: relative;
  height: 100%;
}

.operatingHours {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  top: 18px;
  left: 18px;
  z-index: 1000;
}

.operatingHoursText {
  font-family: "Segoe UI Semibold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}
